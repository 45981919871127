import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "nego_slider p-4  d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_slider = _resolveComponent("a-slider")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock("label", {
          key: 0,
          for: 'nego_slider_' + _ctx.label,
          class: "form-label"
        }, _toDisplayString(_ctx.$t(_ctx.label)), 9, ["for"]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_slider, {
      min: _ctx.min,
      max: _ctx.max,
      value: _ctx.sliderValue,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.sliderValue = $event)),
      range: "",
      disabled: !_ctx.disabled,
      "tip-formatter": _ctx.sliderTooltipFormater,
      "tooltip-visible": true
    }, null, 8, ["min", "max", "value", "disabled", "tip-formatter"])
  ]))
}