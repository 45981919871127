
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FormCheckbox',
    emits: ['update:value'],
    watch: { date: 'updateValue' },
    methods: {
        updateValue() {
            this.$emit('update:value', this.inputValue);
        }
    },
    data() {
        return {
            inputValue: this.value || false
        };
    },
    props: {
        label: { type: String, required: false },
        value: { type: null, required: true },
        disabled: { tyme: Boolean, required: false }
    }
});
