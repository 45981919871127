import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row nego-agenda mb-auto mt-auto px-3" }
const _hoisted_2 = { class: "col-3 py-3 h-100" }
const _hoisted_3 = { class: "d-flex flex-row w-100 py-3" }
const _hoisted_4 = { class: "col-5" }
const _hoisted_5 = { class: "col-5 ms-auto me-0" }
const _hoisted_6 = { class: "nego-bg-red text-center fw-bold fs-1 text-white py-2" }
const _hoisted_7 = { class: "col-9 d-flex justify-content-center h-100 " }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nego_radio = _resolveComponent("nego-radio")
  const _component_nego_list_checkbox = _resolveComponent("nego-list-checkbox")
  const _component_nego_card = _resolveComponent("nego-card")
  const _component_nego_dropdown = _resolveComponent("nego-dropdown")
  const _component_nego_slider = _resolveComponent("nego-slider")
  const _component_nego_calendar = _resolveComponent("nego-calendar")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_nego_radio, {
        title: 'data.choice.campaignsAll',
        label: 'buttons.filters',
        value: _ctx.isFilter,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFilter = $event))
      }, null, 8, ["title", "label", "value"]),
      _createVNode(_component_nego_card, { title: 'data.type.goal' }, {
        default: _withCtx(() => [
          _createVNode(_component_nego_list_checkbox, {
            class: "nego-card-big",
            data: _ctx.listCheckBoxData,
            "onUpdate:data": _cache[2] || (_cache[2] = ($event: any) => (_ctx.listCheckBoxData = $event)),
            disabled: _ctx.isFilter
          }, null, 8, ["data", "disabled"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_nego_card, { title: 'data.type.range' }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_3, [
            _createVNode("div", _hoisted_4, [
              _createVNode(_component_nego_dropdown, {
                value: _ctx.selectedPeriode.start,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedPeriode.start = $event)),
                disabled: _ctx.isFilter
              }, null, 8, ["value", "disabled"])
            ]),
            _createVNode("div", _hoisted_5, [
              _createVNode(_component_nego_dropdown, {
                value: _ctx.selectedPeriode.end,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectedPeriode.end = $event)),
                disabled: _ctx.isFilter
              }, null, 8, ["value", "disabled"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_nego_card, { title: 'data.type.remainingStock' }, {
        default: _withCtx(() => [
          _createVNode(_component_nego_slider, {
            disabled: _ctx.isFilter,
            "onUpdate:disabled": _cache[5] || (_cache[5] = ($event: any) => (_ctx.isFilter = $event)),
            min: 1,
            max: 200,
            value: _ctx.sliderValue,
            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (_ctx.sliderValue = $event))
          }, null, 8, ["disabled", "value"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_nego_card, { title: 'data.type.campaignsNumber' }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_6, _toDisplayString(_ctx.campaignsNumber), 1)
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _createVNode("div", _hoisted_7, [
      _createVNode(_component_nego_calendar, {
        value: _ctx.selectedPeriode,
        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (_ctx.selectedPeriode = $event)),
        disabled: _ctx.isFilter,
        "onUpdate:disabled": _cache[8] || (_cache[8] = ($event: any) => (_ctx.isFilter = $event)),
        minDate: new Date(),
        isRange: true,
        mask: 'YYYY-MM-DD'
      }, null, 8, ["value", "disabled", "minDate"])
    ])
  ]))
}