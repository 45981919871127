
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Footer',
    props: {
        copyright: {
            type: String,
            required: false
        },
        legals: {
            type: String,
            required: false
        },
        cgu: {
            type: String,
            required: false
        }
    }
});
