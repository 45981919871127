import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "navbar navbar-light bg-light" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "col-12 d-flex flex-row" }
const _hoisted_4 = {
  key: 0,
  class: "d-flex ms-auto me-0"
}
const _hoisted_5 = { class: "nego-grey fs-5 fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("nav", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString() + " ", 1),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.menu.data, (menuItem) => {
          return (_openBlock(), _createBlock("a", {
            class: ["nav-link neo-nav-link fs-5 fw-bold", 
                        _ctx.$router.currentRoute.value.path.toLowerCase() ===
                        menuItem.goTo.toLowerCase()
                            ? 'neo-nav-link-active '
                            : ''
                    ],
            key: '_nav_' + menuItem.title,
            "aria-current": "page",
            onClick: ($event: any) => (_ctx.redirect(menuItem.goTo))
          }, _toDisplayString(_ctx.$t(menuItem.title).toUpperCase()), 11, ["onClick"]))
        }), 128)),
        (_ctx.menu.rightAction)
          ? (_openBlock(), _createBlock("div", _hoisted_4, [
              _createVNode("button", {
                class: "btn btn-transparent d-flex flex-row",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.menu.rightAction.fn()))
              }, [
                _createVNode("img", {
                  src: _ctx.menu.rightAction.icon,
                  width: "30",
                  class: "me-3"
                }, null, 8, ["src"]),
                _createVNode("div", _hoisted_5, _toDisplayString(_ctx.$t(_ctx.menu.rightAction.title).toUpperCase()), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}