import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_date_picker = _resolveComponent("v-date-picker")

  return (_openBlock(), _createBlock(_component_v_date_picker, {
    modelValue: _ctx.date,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.date = $event)),
    columns: _ctx.$screens({ default: 2 }),
    rows: _ctx.$screens({ default: 2 }),
    "is-expanded": _ctx.$screens({ default: false }),
    "select-attribute": _ctx.selectDragAttribute,
    "drag-attribute": _ctx.selectDragAttribute,
    minDate: _ctx.minDate,
    "model-config": { type: 'string', mask: _ctx.mask },
    onDrag: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dragValue = $event)),
    "is-range": "",
    "show-weeknumbers": "",
    class: "px-5"
  }, null, 8, ["modelValue", "columns", "rows", "is-expanded", "select-attribute", "drag-attribute", "minDate", "model-config"]))
}