
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Radio',
    props: {
        label: { type: String, required: false },
        value: { type: Boolean, required: false },
        title: { type: String, required: false }
    },
    watch: {
        isChecked: 'updateValue'
    },
    methods: {
        updateValue() {
            this.$emit('update:value', this.isChecked);
        }
    },
    data() {
        return { isChecked: this.value };
    }
});
