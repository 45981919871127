
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Card',
    props: {
        title: {
            type: String,
            required: false
        }
    },
    components: {}
});
