import { createMemoryHistory, createRouter,   RouteRecordRaw } from 'vue-router';
import {Agenda,Analyse,CampaignCreation,CampaignDelete,CampaignEdition,Home,Live,Login,Planning} from '@/views'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/agenda',
        name: 'Agenda',
        component: Agenda
    },
    {
        path: '/live',
        name: 'Live',
        component: Live
    },
    {
        path: '/analysis',
        name: 'Analyse',
        component: Analyse
    },
    {
        path: '/planning',
        name: 'Planning',
        component: Planning },
    {
        path: '/campaign-creation',
        name: 'Campaign Creation',
        component: CampaignCreation
     },
    {
        path: '/campaign-edition',
        name: 'Campaign Edition',
        component: CampaignEdition  },
    {
        path: '/campaign-delete',
        name: 'Campaign Delete',
        component: CampaignDelete  },
    { path: '/:pathMatch(.*)*', redirect: { name: 'Login' } }
];

const router = createRouter({
    history: createMemoryHistory(),
    routes
});

export default router;
