import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-3 w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock("label", {
          key: 0,
          for: `_nego_form_explain_${_ctx.label}`,
          class: "form-label fw-bold fs-6 nego-muted"
        }, _toDisplayString(_ctx.toUpperCase(_ctx.label)), 9, ["for"]))
      : _createCommentVNode("", true),
    _createVNode("div", {
      id: `_nego_form_explain_${_ctx.label}`,
      class: "w-100 nego-muted"
    }, _toDisplayString(_ctx.text), 9, ["id"])
  ]))
}