
import { defineComponent } from 'vue';
import { IHeaderLogButton } from './Header';

export default defineComponent({
    name: 'Header',
    components: {},
    props: {
        logo: {
            type: String,
            required: false
        },
        user: {
            type: String,
            required: false
        },
        useLocalStoreForLogin: {
            type: Boolean,
            required: false
        },
        logButton: {
            type: Object as () => IHeaderLogButton,
            required: false
        }
    }
});
