import { createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row align-content-center position-relative" }
const _hoisted_2 = { class: "col-12 justify-content-center d-flex" }
const _hoisted_3 = { class: "col-12 justify-content-center d-flex  pt-0 m-0" }
const _hoisted_4 = { class: "btn btn-transparent interact fs-4 d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nego_input_range_calendar = _resolveComponent("nego-input-range-calendar")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("img", {
      class: "bg_analyse_magni m-4",
      src: require('@/assets/svg/magnifying.svg')
    }, null, 8, ["src"]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_nego_input_range_calendar, { label: 'Analyse des campagnes' })
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("button", _hoisted_4, [
        _createTextVNode(_toDisplayString(_ctx.toUpperCase(_ctx.$t('actions.downloadFile'))) + " ", 1),
        _createVNode("img", {
          class: "m-l-3",
          width: "25",
          height: "25",
          src: require('@/assets/svg/excel_actions.svg')
        }, null, 8, ["src"])
      ])
    ])
  ]))
}