
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Calendar',
    emits: ['update:value'],
    props: {
        value: { type: Object, required: true },
        minDate: { type: Date, required: true },
        isRange: { type: Boolean, required: false },
        mask: { type: String, required: true }
    },
    components: {},
    data() {
        return {
            dragValue: null,
            date: { start: null, end: null }
        };
    },
    watch: { date: 'updateValue' },
    methods: {
        updateValue() {
            this.$emit('update:value', this.date);
        }
    },
    computed: {
        selectDragAttribute() {
            return {
                popover: {
                    visibility: 'hover',
                    isInteractive: false // Defaults to true when using slot
                }
            };
        }
    }
});
