import VCalendar from 'v-calendar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import { Slider, Divider, Tooltip, Statistic } from 'ant-design-vue';
//@ts-ignore
import InlineSvg from 'vue-inline-svg';
import 'ant-design-vue/dist/antd.css';
//@ts-ignore
import VSlider from '@vueform/slider';

function installExternals(app: any) {
    app.component('inline-svg', InlineSvg);

    app.use(Slider);
    app.use(Divider);
    app.use(Tooltip);
    app.use(Statistic);

    app.use(VCalendar, {});
    app.component('VSliders', VSlider);
}

export { installExternals };
