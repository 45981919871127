//@ts-nocheck
import moment from 'moment';
moment.updateLocale('fr', {
    week: {
        dow: 1
    }
});
export const dateToHuman = (date: any, format?: string) => {
    var date = moment(date).format(format || 'dddd DD MMMM YYYY - HH:mm');
    date = date.charAt(0).toUpperCase() + date.slice(1);
    return date;
};
export const dateToHumanShort = (date: any) =>
    dateToHuman(date, 'DD/MM/YYYY HH:mm');
export const toUpperCase = (value: string) => value.toUpperCase();

export const capitalyze = (value: string) =>
    value.charAt(0).toUpperCase() + value.slice(1);
