import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "alert nego_alert fw-bold nego_alert_bg py-4 p-0 m-0 w-100 d-flex flex-row align-items-center justify-content-center",
  role: "alert"
}
const _hoisted_2 = { class: "col-6 fw-bold text-center fs-5" }
const _hoisted_3 = { class: "ps-4 col-4 d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", null, _toDisplayString(_ctx.toUpperCase(_ctx.$t(_ctx.content))), 1)
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("button", {
        class: "mx-auto fw-bold btn nego_red_alert rounded rounded-pill text-white px-5",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onConfirm()))
      }, _toDisplayString(_ctx.toUpperCase(_ctx.$t(_ctx.btnContent))), 1),
      _createVNode("button", {
        class: "mx-auto fw-bold btn nego_black rounded rounded-pill text-white px-5",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onCancel()))
      }, _toDisplayString(_ctx.toUpperCase(_ctx.$t(_ctx.btnCancelContent))), 1)
    ])
  ]))
}