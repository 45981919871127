import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row row-cols-3" }
const _hoisted_3 = { class: "card nego-live-comp" }
const _hoisted_4 = { class: "d-flex flex-row w-100 justify-content-between" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "row d-flex text-center h-100 align-content-center justify-content-center" }
const _hoisted_7 = { class: "col-12 fw-bold" }
const _hoisted_8 = { class: "nego-muted" }
const _hoisted_9 = { class: "col-12 m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")
  const _component_a_statistic = _resolveComponent("a-statistic")
  const _component_nego_card_stats = _resolveComponent("nego-card-stats")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(Object.keys(_ctx.kpiToDisplay), (kpi, index) => {
        return (_openBlock(), _createBlock("div", {
          class: "col ",
          key: '_card_stats_' + kpi + '_' + index
        }, [
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_nego_card_stats, {
              key: 'nego_live_stats_card' + kpi,
              class: [{
                            _card_stats_fixed_background: !_ctx.kpiToDisplay[kpi]
                                .radiantEffect,
                            'text-white': !_ctx.kpiToDisplay[kpi].radiantEffect
                        }, "_card_stats_fixed m-5 bg-transparent"],
              style: {
                            background: 'rgb(228, 228, 228)',
                            backgroundImage: `-moz-linear-gradient(
        0deg,
       rgba(149, 152, 154, 1) 0%,
       rgba(149, 152, 154, 1) ${
           _ctx.data[_ctx.kpiToDisplay[kpi].props.percent]
               ? _ctx.data[_ctx.kpiToDisplay[kpi].props.percent] + 1 + '%'
               : '100%'
       },
        rgba(228, 228, 228, 1) ${
            _ctx.data[_ctx.kpiToDisplay[kpi].props.percent]
                ? _ctx.data[_ctx.kpiToDisplay[kpi].props.percent] + '%'
                : '100%'
        },
        rgba(228, 228, 228, 1) 100%
    )`,
                            backgroundImage: ` -webkit-linear-gradient(
        0deg,
          rgba(149, 152, 154, 1) 0%,
       rgba(149, 152, 154, 1) ${
           _ctx.data[_ctx.kpiToDisplay[kpi].props.percent]
               ? _ctx.data[_ctx.kpiToDisplay[kpi].props.percent] + 1 + '%'
               : '100%'
       },
        rgba(228, 228, 228, 1) ${
            _ctx.data[_ctx.kpiToDisplay[kpi].props.percent]
                ? _ctx.data[_ctx.kpiToDisplay[kpi].props.percent] + '%'
                : '100%'
        },
        rgba(228, 228, 228, 1) 100%
    )`,
                            backgroundImage: ` linear-gradient(
        0deg,
        rgba(149, 152, 154, 1)  0%,
      rgba(149, 152, 154, 1)  ${
          _ctx.data[_ctx.kpiToDisplay[kpi].props.percent]
              ? _ctx.data[_ctx.kpiToDisplay[kpi].props.percent] + 1 + '%'
              : '100%'
      },
        rgba(228, 228, 228, 1)${
            _ctx.data[_ctx.kpiToDisplay[kpi].props.percent]
                ? _ctx.data[_ctx.kpiToDisplay[kpi].props.percent] + '%'
                : '100%'
        },
        rgba(228, 228, 228, 1)100%
    )`
                        }
            }, {
              header: _withCtx(() => [
                _createVNode("div", _hoisted_4, [
                  _createVNode("div", null, _toDisplayString(_ctx.$t('live.' + kpi)), 1),
                  _createVNode("div", _hoisted_5, [
                    _createVNode(_component_inline_svg, {
                      src: 
                                            require('@/assets/svg/kpi/' +
                                                kpi.toLowerCase() +
                                                '.svg')
                                        ,
                      width: "30",
                      height: "30"
                    }, null, 8, ["src"])
                  ])
                ])
              ]),
              body: _withCtx(() => [
                _createVNode("div", _hoisted_6, [
                  _createVNode("div", _hoisted_7, [
                    _createVNode(_component_a_statistic, {
                      value: 
                                            _ctx.data[_ctx.kpiToDisplay[kpi].props.value]
                                        ,
                      class: {
                                            text_white_force: !_ctx.kpiToDisplay[kpi]
                                                .radiantEffect
                                        }
                    }, {
                      suffix: _withCtx(() => [
                        _createVNode("span", _hoisted_8, _toDisplayString(_ctx.data[
                                                    _ctx.kpiToDisplay[kpi].props
                                                        .percent
                                                ]
                                                    ? _ctx.data[
                                                          _ctx.kpiToDisplay[kpi]
                                                              .props.percent
                                                      ] + ' %'
                                                    : ''), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "class"])
                  ]),
                  _createVNode("div", _hoisted_9, [
                    _createVNode("div", null, _toDisplayString(_ctx.$t('live.help.' + kpi)), 1)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["class", "style"])
          ])
        ]))
      }), 128))
    ])
  ]))
}