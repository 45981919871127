import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "nego-form-drodown d-flex flex-row" }
const _hoisted_3 = { selected: "" }
const _hoisted_4 = { class: "btn btn-transparent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tooltip = _resolveComponent("a-tooltip")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock("label", {
          key: 0,
          for: `_nego_form_dropdown_${_ctx.label}`,
          class: "form-label fw-bold fs-6 nego-basic-label"
        }, _toDisplayString(_ctx.toUpperCase(_ctx.label)), 9, ["for"]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_2, [
      _createVNode("select", {
        class: "form-select",
        disabled: _ctx.disabled,
        "aria-label": "Default select example"
      }, [
        _createVNode("option", _hoisted_3, _toDisplayString(_ctx.value), 1)
      ], 8, ["disabled"]),
      (_ctx.help)
        ? (_openBlock(), _createBlock(_component_a_tooltip, {
            key: 0,
            title: _ctx.help.content,
            placement: "top",
            "arrow-point-at-center": ""
          }, {
            default: _withCtx(() => [
              _createVNode("button", _hoisted_4, [
                _createVNode("img", {
                  src: require('@/assets/svg/help.svg'),
                  width: "20"
                }, null, 8, ["src"])
              ])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true)
    ])
  ]))
}