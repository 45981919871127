
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Template',
    props: {
        value: { type: String, required: true },
        data: { type: Array, required: true },
        disabled: { type: Boolean, required: false }
    }
});
