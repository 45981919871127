
import { defineComponent, reactive, Ref, ref } from 'vue';
import LiveComponent from '@/components/Live.vue';
import { fakeData } from '@/services';

export default defineComponent({
    name: 'Live',
    components: { LiveComponent },
    setup() {
        const kpiToDisplay: Ref<{
            [key: string]: {
                type: string;
                radiantEffect: boolean;
                props: { value?: any; percent?: any };
            };
        }> = ref({
            engaged: {
                type: 'number || percent',
                radiantEffect: true,
                props: { value: 'engaged', percent: 'engagedRate' }
            },
            conclued: {
                type: 'number || percent',
                radiantEffect: true,
                props: { value: 'conclued', percent: 'concluedRate' }
            },
            added: {
                type: 'number || percent',
                radiantEffect: true,
                props: { value: 'added', percent: 'addedRate' }
            },
            notConcluded: {
                type: 'number || percent',
                radiantEffect: true,
                props: { value: 'notConcluded', percent: 'notConcludedRate' }
            },
            notConcludedInsufficiency: {
                type: 'number || percent',
                radiantEffect: true,
                props: {
                    value: 'notConcludedInsufficiency',
                    percent: 'notConcludedInsufficiencyRate'
                }
            },
            notConcludedInconsistency: {
                type: 'number || percent',
                radiantEffect: true,
                props: {
                    value: 'notConcludedInconsistency',
                    percent: 'notConcludedInconsistencyRate'
                }
            },
            negociators: {
                type: 'number',
                radiantEffect: true,
                props: { value: 'negociators', percent: null }
            },
            transactionsPerNegociator: {
                type: 'number || percent',
                radiantEffect: true,
                props: {
                    value: 'transactionsPerNegociator',
                    percent: 'transactionsPerNegociatorRate'
                }
            },
            favorites: {
                type: 'array',
                radiantEffect: false,
                props: { value: 'favorites', percent: null }
            },
            stockLeft: {
                type: 'number || percent',
                radiantEffect: true,
                props: { value: 'stockLeft', percent: 'stockLeftRate' }
            },
            futurStock: {
                type: 'number || percent',
                radiantEffect: true,
                props: { value: 'futurStock', percent: 'futurStockRate' }
            },
            elasticityRate: {
                type: 'number',
                radiantEffect: false,
                props: { value: 'elasticityRate', percent: null }
            },
            lapNumberOnEngaged: {
                type: 'number',
                radiantEffect: false,
                props: { value: 'lapNumberOnEngaged', percent: null }
            },
            campaignRate: {
                type: 'number',
                radiantEffect: false,
                props: { value: 'campaignRate', percent: null }
            }
            // timeLeft: {
            //     type: 'time || range',
            //     radiantEffect: true,
            //     props: { value: 'campaignRate', percent: null }
            // }
        });

        const data = ref(null);
        fakeData().subscribe(value => {
            data.value = value;
        });
        return { ...reactive({ data }), kpiToDisplay };
    }
});
