
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FormTextArea',
    emits: ['update:value'],
    props: {
        label: { type: String, required: true },
        value: { type: String, required: true },
        placeHolder: { type: String, required: false },
        type: { type: String, required: false }
    }
});
