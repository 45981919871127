import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row py-3 px-2 justify-content-center py-3 px-2" }
const _hoisted_4 = { class: "col-10 fs-2" }
const _hoisted_5 = {
  key: 0,
  class: "row row-cols-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LiveComponent = _resolveComponent("LiveComponent")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, _toDisplayString(_ctx.dateToHuman(new Date())), 1)
      ])
    ]),
    (_ctx.data)
      ? (_openBlock(), _createBlock("div", _hoisted_5, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.data, (subData, index) => {
            return (_openBlock(), _createBlock("div", {
              class: "col",
              key: '_live_componenent_' + index
            }, [
              _createVNode(_component_LiveComponent, {
                data: subData.liveData,
                kpiToDisplay: _ctx.kpiToDisplay,
                class: "w-100 h-100 py-3 px-2"
              }, null, 8, ["data", "kpiToDisplay"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}