import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-3 w-100 h-100" }
const _hoisted_2 = { class: "d-flex f-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock("label", {
          key: 0,
          for: `_nego_form_input_${_ctx.label}`,
          class: "form-label fw-bold fs-6 nego-basic-label"
        }, _toDisplayString(_ctx.toUpperCase(_ctx.label)), 9, ["for"]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_2, [
      _createVNode("input", {
        class: "form-check-input nego-form-checkbox",
        type: "checkbox",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputValue = !_ctx.inputValue)),
        id: 'nego_list_checkbox_' + _ctx.label
      }, null, 8, ["id"])
    ])
  ]))
}