import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock("label", {
          key: 0,
          for: `_nego_form_input_${_ctx.label}`,
          class: "form-label nego-basic-label  fw-bold fs-6"
        }, _toDisplayString(_ctx.toUpperCase(_ctx.label)), 9, ["for"]))
      : _createCommentVNode("", true),
    _createVNode("textarea", {
      type: _ctx.type || '',
      class: "form-control",
      id: `_nego_form_input_${_ctx.label}`,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:value', $event.target.value))),
      placeholder: _ctx.placeHolder || '',
      rows: "3"
    }, null, 40, ["type", "id", "placeholder"])
  ]))
}