
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FormDatePicker',
    emits: ['update:value'],
    props: {
        label: { type: String, required: false },
        value: { type: String, required: true },
        mask: { type: String, required: false },
        noCalendar: { type: Boolean, required: false },
        mode: { type: String, required: false },
        durationMode: { type: Boolean, required: false }
    },
    data() {
        return {
            date: { start: null, end: null },
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD hh:mm',
                timeAdjust: '12:00:00'
            }
        };
    },
    watch: { date: 'updateValue' },
    methods: {
        updateValue() {
            this.$emit('update:value', this.date);
        }
    },
    computed: {
        errorMessage() {
            if (!this.date) return 'Date is required.';
            return '';
        }
    }
});
