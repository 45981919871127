
import { defineComponent } from 'vue';
import { ICheckBox } from './ListCheckbox';
export default defineComponent({
    name: 'ListCheckBox',
    emits: ['update:data'],
    props: {
        data: { type: Array as () => ICheckBox[] },
        disabled: { type: Boolean, required: false }
    },
    watch: { data: 'updateData' },
    methods: {
        updateData() {
            this.$emit('update:data', this.data);
        }
    }
});
