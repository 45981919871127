import { createStore } from 'vuex';

export default createStore({
    state: { user: { isLogged: false }, lastActiveCampagnSelected: null },
    mutations: {
        login(state) {
            state.user.isLogged = true;
        },
        logout(state) {
            state.user.isLogged = false;
        },
        setLastActiveCampagnSelected(state, payload) {
            state.lastActiveCampagnSelected = payload;
        }
    },
    actions: {},
    modules: {}
});
