
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LoginComponent',
    components: {},
    methods: {
        submit() {
            this.$store.commit('login');
            this.$router.push('/planning');
        }
    }
});
