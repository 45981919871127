
import { defineComponent } from 'vue';

import { IHelp } from './../index.d';

export default defineComponent({
    name: 'FormDropDown',
    props: {
        label: { type: String, required: false },
        value: { type: String, required: true },
        data: { type: Array, required: false }, // true in final version
        disabled: { type: Boolean, required: false },
        help: { type: Object as () => IHelp, required: false }
    }
});
