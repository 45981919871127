import { createI18n } from 'vue-i18n';

const messages = {
    en: {},
    fr: {
        buttons: { filters: 'Filtres' },
        actions: {
            valid: 'Valider',
            logout: 'Quitter',
            edit: 'Modifier',
            delete: 'Supprimer',
            newCampaign: 'Nouvelle Campagne',
            browse: 'Parcourir',
            uploadFile: 'Uploader le fichier',
            downloadFile: 'Telecharger le fichier'
        },
        live: {
            engaged: 'Transactions engages',
            conclued: 'Transactions conclues',
            added: 'Transactions ajoutées',
            notConcluded: 'Transactions non conclues',
            notConcludedInsufficiency:
                'Transactions non conclues pour insuffisance',
            notConcludedInconsistency:
                'Transactions non conclues pour incoherence',
            negociators: 'Negociateurs',
            transactionsPerNegociator: 'Nombre de transactions par négociateur',
            favorites: 'Favoris',
            timeLeft: 'Temps restant',
            stockLeft: 'Stock restant',
            futurStock: 'Stock previsionnel',
            elasticityRate: 'Taux delasticité',
            lapNumberOnEngaged: 'Nombre de tours / Transaction',
            campaignRate: 'Succes de la campagne',
            help: {
                engaged: 'Nombre de transactions engagées',
                conclued: 'Nombre de transactions abouties',
                added: 'Nombre de transactions ajoutées au panier',
                notConcluded: 'Nombre de transactions non abouties',
                notConcludedInsufficiency:
                    'Nombre de transactions non commencées pour propositions inférieures au taux de trappe',
                notConcludedInconsistency:
                    'Nombre de transactions interronpues pour réponses incohérentes',
                negociators: 'NegocNombre de clients négociateurs',
                transactionsPerNegociator:
                    'Nombre de produits négociés par client négociateur',
                favorites: 'Produits les plus négociés',
                stockLeft: 'Stock restant de produits négociables',
                futurStock:
                    'Prévision de stock restant a la fin de la campagne',
                elasticityRate:
                    "Nombre de produits négociés et vendus en rapport avec le nmdre de produits vendus comparé au nombre de produits négociables par rapport à l'ensemble des produits à vendre",
                lapNumberOnEngaged:
                    "Nombre d'aller-retour moyen au cours des transactions",
                campaignRate:
                    'Part des produits négociés dans un panier en moyenne'
            }
        },
        menu: { planning: 'Planning', live: 'Live', analysis: 'Analyse' },
        forms: {
            rememberMe: 'Se souvenir de moi',
            resetPassword: 'Réinitialiser mon mot de passe',
            password: 'Mot de passe',
            mail: 'E-mail',
            confirmDelete: 'Confirmez vous la suppression de la campagne ?',
            confirmEdit: 'Confirmez vous la modification de la campagne ?'
        },
        data: {
            choice: {
                campaignsAll: 'Toutes les campagnes'
            },
            type: {
                goal: 'Objectif',
                range: 'Periode',
                remainingStock: 'Stock restant',
                remainingNegoStock: 'Stock de produits négociables',
                remainingTime: 'Temps restant',
                campaignsNumber: 'Nombre de campagne'
            }
        },
        commons: {
            yes: 'Oui',
            no: 'Non',
            modal: 'Modal',
            ref: 'Référence',
            goal: 'Objectif',
            number: 'Numero',
            alias: 'Designation',
            type: 'Type',
            negoCtx: 'Contexte de la négociation',
            begin: 'Début',
            end: 'Fin',
            duration: 'Durée',
            campaignDuration: 'Durée de la campagne',
            negoTechnique: 'Téchnique de négociation',
            layout: 'Interfaces',
            negociableProduct: 'Produits négociables',
            access: 'Acces',
            transaction: {
                lapNumber:
                    "NOMBRE MAXIMUM DE PROPOSITIONS  DE L'ACHETEUR PENDANT UNE TRANSACTION",
                lapNumberHelp:
                    "Une transaction est une période pendant laquelle l'acheteur et le vendeur vont tenter de trouver un prix d'accord. Au cours de cette transaction, l'acheteur pourra faire un nombre de propositions.",
                duration: "Durée d'une transaction",
                durationHelp:
                    "Période pendant laquelle l'acheteur va pouvoir proposer faire des propositions de prix croissantes. La durée est raccourcie si un prix d'accord est trouvé.",
                trapRate: 'Taux de trappe',
                trapRateHelp:
                    'Il s\'agit du "prix d\'entrée" en dessous duquel un acheteur ne peut pas entrer dans la négociation. Ce taux est exprimé en % du prix de vente initial.',
                trapNumber: 'Nombre de trappes maximum par transaction',
                trapNumberHelp:
                    "L'accès à une transaction d'un produit sera fermée si l'acheteur fait 2 propositions inférieures à 30 % du prix de vente. ",
                transactionOrNegoTryNumber:
                    'Nombre de transactions par produit',
                transactionOrNegoTryNumberHelp:
                    'Au delà de ce nombre de transactions, un produit ne sera plus négociable pendant la campagne.',
                exclusionOrCloseLimite:
                    "Seul d'exclusion d'une transaction pour propositions incoherentes",
                exclusionOrCloseLimiteHelp:
                    "Au cours d'une transaction, chaque nouvelle proposition de l'acheteur doit être supérieure à la précédente. Au delà de ce seuil, la transaction est interrompue par anticipation pour propositions incohérentes.",
                incoherentNegoNumber:
                    "Seuil d'exclusion de la campagne pour cause de trappes",
                incoherentNegoNumberHelp:
                    'Nombre d\'interruptions de transactions par anticipation pour "prix d\'entrée" trop bas provoquant la fermeture anticipée de la campagne.',
                negociableAgain:
                    "Permettre la negociation d'un même produit deja negocie et ajoute au panier"
            }
        }
    }
};

// 2. Create i18n instance with options
export const i18n = createI18n({
    locale: 'fr', // set locale
    fallbackLocale: 'fr', // set fallback locale
    messages // set locale messages
    // If you need to specify other options, you can set other options
    // ...
});

export const setLocal = (locale: string) => {
    i18n.global.locale = locale;
};
