import { of, Observable } from 'rxjs';

export const fakeData = (): Observable<any> => {
    return of([
        {
            title: "DEBUT D'AUTOMNE",
            type: 'DESTOCKAGE',
            content:
                'Voyez ce jeu exquis wallon, de graphie en kit mais bref. Portez ce vieux whisky au juge blond qui fume sur son île intérieure, à côté de l"alcôve ovoïde, où les bûches se consument dans l"âtre, ce qui lui permet de penser à la cænogenèse de l"être dont il est question dans la cause ambiguë entendue à Moÿ, dans un capharnaüm qui, pense-t-il, diminue çà et là la qualité de son œuvre. Prouvez, beau juge, que le fameux sandwich au yak tue. L"île exiguë, Où l"obèse jury mûr Fête l"',
            stockTotal: 500,
            stockUsed: Math.floor(Math.random() * (500 - +1)) + 1,
            range: {
                start: new Date(),
                end: new Date().setDate(new Date().getDate() + 1)
            },
            liveData: {
                transactionTotal: 600,
                engaged: 500,
                engagedRate: 100,
                conclued: 400,
                concluedRate: 80,
                added: 300,
                addedRate: 60,
                notConcluded: 100,
                notConcludedRate: 100,
                notConcludedInsufficiency: 40,
                notConcludedInsufficiencyRate: 40,
                notConcludedInconsistency: 50,
                notConcludedInconsistencyRate: 50,
                negociators: 250,
                transactionsPerNegociator: 2,
                transactionsPerNegociatorRate: 2,
                favorites: [
                    'Baskets',
                    'Boots',
                    'Boots Santiag',
                    'Bottes',
                    'Bottines',
                    'Chaussons'
                ],
                stockLeft: 200,
                stockLeftRate: 200,
                futurStock: 20,
                futurStockRate: 56,
                elasticityRate: 2,
                lapNumberOnEngaged: 2.2,
                campaignRate: 45
            }
        },
        {
            title: 'DEBUT JUIN',
            type: 'DESTOCKAGE',
            content:
                'Voyez ce jeu exquis wallon, de graphie en kit mais bref. Portez ce vieux whisky au juge blond qui fume sur son île intérieure, à côté de l"alcôve ovoïde, où les bûches se consument dans l"âtre, ce qui lui permet de penser à la cænogenèse de l"être dont il est question dans la cause ambiguë entendue à Moÿ, dans un capharnaüm qui, pense-t-il, diminue çà et là la qualité de son œuvre. Prouvez, beau juge, que le fameux sandwich au yak tue. L"île exiguë, Où l"obèse jury mûr Fête l"',
            stockTotal: 1200,
            stockUsed: Math.floor(Math.random() * (1200 - 1 + 1)) + 1,
            range: {
                start: new Date().setDate(new Date().getDate() - 2),
                end: new Date().setDate(new Date().getDate() + 2)
            },
            liveData: {
                transactionTotal: 600,
                engaged: 500,
                engagedRate: 100,
                conclued: 400,
                concluedRate: 80,
                added: 300,
                addedRate: 60,
                notConcluded: 100,
                notConcludedRate: 100,
                notConcludedInsufficiency: 40,
                notConcludedInsufficiencyRate: 40,
                notConcludedInconsistency: 50,
                notConcludedInconsistencyRate: 50,
                negociators: 250,
                transactionsPerNegociator: 2,
                transactionsPerNegociatorRate: 2,
                favorites: [
                    'Baskets',
                    'Boots',
                    'Boots Santiag',
                    'Bottes',
                    'Bottines',
                    'Chaussons'
                ],
                stockLeft: 200,
                stockLeftRate: 200,
                futurStock: 20,
                futurStockRate: 56,
                elasticityRate: 2,
                lapNumberOnEngaged: 2.2,
                campaignRate: 45
            }
        }
    ]);
};
