
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AgendaComponent',
    components: {},
    data() {
        return {
            sliderValue: 10,
            campaignsNumber: 10,
            selectedPeriode: {},
            isFilter: true,
            listCheckBoxData: [
                { label: 'Destockage', value: false, quantity: 7 },
                { label: 'Fidélisation', value: false, quantity: 8 }
            ]
        };
    },
    methods: {}
});
