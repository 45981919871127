import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row pt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CampainTemplate = _resolveComponent("CampainTemplate")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_CampainTemplate, { alert: {
                content: 'forms.confirmEdit',
                btnContent: 'commons.yes',
                btnCancelContent: 'commons.no'
            } }, null, 8, ["alert"])
  ]))
}