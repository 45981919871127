
import { defineComponent, onMounted } from 'vue';

import { INegoMenu } from '@/lib/nego-interfaces';

export default defineComponent({
    data() {
        return {
            menu: {
                data: [
                    { title: 'menu.planning', goTo: '/planning' },
                    { title: 'menu.live', goTo: '/live' },
                    { title: 'menu.analysis', goTo: '/analysis' }
                ],
                rightAction: {
                    title: 'actions.newCampaign',
                    icon: require('./assets/svg/add.svg'),
                    fn: () => {
                        this.$router.push('/campaign-creation');
                    }
                }
            } as INegoMenu
        };
    },
    methods: {
        logout() {
            this.$store.commit('logout');
            this.$router.push('/login');
        }
    },
    setup() {
        onMounted(() => {
            if (process.env.NODE_ENV === 'production')
                console.log('production');
        });
    }
});
