import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row justify-content-center px-4 position-relative" }
const _hoisted_2 = {
  key: 0,
  class: "header_logo w-auto me-0 py-2 ms-auto position-absolute"
}
const _hoisted_3 = {
  key: 1,
  class: "header_user w-auto me-0 py-2 ms-auto d-flex"
}
const _hoisted_4 = {
  key: 0,
  class: "header_user w-auto me-0 py-2 ms-auto d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.logo)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode("img", { src: _ctx.logo }, null, 8, ["src"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.user)
      ? (_openBlock(), _createBlock("div", _hoisted_3, [
          (_ctx.useLocalStoreForLogin && _ctx.$store.state.user.isLogged)
            ? (_openBlock(), _createBlock("div", _hoisted_4, [
                _createVNode("button", {
                  type: "button",
                  class: "btn btn-transparent",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logButton.fn()))
                }, [
                  _createVNode("img", {
                    src: _ctx.logButton.icon
                  }, null, 8, ["src"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode("img", { src: _ctx.user }, null, 8, ["src"])
        ]))
      : _createCommentVNode("", true)
  ]))
}