
import { IActionCofirm } from '@/lib/nego-interfaces';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CampaignTemplate',
    components: {},
    methods: {},
    props: {
        withConfirm: { type: Boolean, required: false },
        alert: { type: Object as () => IActionCofirm, required: false }
    },
    data() {
        return {
            id: '',
            alias: '',
            checkbox: false
        };
    }
});
