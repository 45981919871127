import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card nego-card" }
const _hoisted_2 = { class: "nego-bg-red text-white fw-bold fs-4 px-2 py-1" }
const _hoisted_3 = { class: "card-body p-0 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header")
    ]),
    _createVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "body")
    ])
  ]))
}