
import { defineComponent, ref, reactive } from 'vue';

export default defineComponent({
    name: 'LiveComponent',
    props: {
        data: { type: Object, required: true },
        kpiToDisplay: { type: Object, required: true }
    },
    data() {
        const radiantTop = ref('0%');
        const radiantBottom = ref('0%');
        console.log(this.data);
        radiantTop.value = this.data.percent + '%';
        radiantBottom.value = this.data.percent + 1 + '%';

        return {
            ...reactive({ radiantTop }),
            ...reactive({ radiantBottom })
        };
    }
});
