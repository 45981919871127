
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ActionCofirm',
    emits: ['confirm', 'cancel'],
    props: {
        content: { type: String, required: false },
        btnContent: { type: String, required: false },
        btnCancelContent: { type: String, required: false },
        type: { type: String, required: false }
    },
    methods: {
        onConfirm(): void {
            this.$emit('confirm', true);
        },
        onCancel(): void {
            this.$emit('cancel', true);
        }
    },
    data() {
        return {
            value: '',
            btnDisabled: true
        };
    }
});
