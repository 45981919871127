import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_router_link, { to: "/CGU" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.cgu), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/LegalNotices" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.cgu), 1)
      ]),
      _: 1
    })
  ]))
}