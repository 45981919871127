import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nego_header = _resolveComponent("nego-header")
  const _component_nego_menu = _resolveComponent("nego-menu")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_nego_header, {
      class: "neo-header",
      logo: require('./assets/logo/negopricing.svg'),
      user: require('./assets/User.svg'),
      useLocalStoreForLogin: true,
      logButton: {
            icon: require('./assets/svg/logout.svg'),
            fn: _ctx.logout
        }
    }, null, 8, ["logo", "user", "logButton"]),
    (_ctx.$store.state.user.isLogged)
      ? (_openBlock(), _createBlock(_component_nego_menu, {
          key: 0,
          menu: _ctx.menu
        }, null, 8, ["menu"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}