
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'Slider',
    emits: ['update:value'],
    props: {
        value: { type: Number, required: true },
        label: { type: String, required: false },
        min: { type: Number, required: true },
        max: { type: Number, required: true },
        disabled: { type: Boolean, required: false }
    },
    setup() {
        const sliderValue = ref([30, 55]);

        const sliderTooltipFormater = (value: number) => {
            return `${value}`;
        };

        return { sliderValue, sliderTooltipFormater };
    },
    watch: { value: 'emitValue' },
    methods: {
        emitValue() {
            this.$emit('update:value', this.value);
        }
    }
});
