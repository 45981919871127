import {
    NegoMenu,
    NegoHeader,
    NegoFooter,
    NegoCard,
    NegoCardData,
    NegoCardStats,
    NegoSlider,
    NegoSliderData,
    NegoListCheckBox,
    NegoCalendar,
    NegoRadio,
    NegoDropdown,
    NegoFormTitle,
    NegoFormInput,
    NegoTextArea,
    NegoFormDropDown,
    NegoFormDatePicker,
    NegoFormInputRangeDatePicker,
    NegoFromExplain,
    NegoFormActionConfirm,
    NegoFormCheckBox
    // NegoForm
} from './component';

import { installExternals } from './externals';
import {
    toUpperCase,
    dateToHuman,
    capitalyze,
    dateToHumanShort
} from './globals';

function install(app: any) {
    // globals functions
    app.config.globalProperties.toUpperCase = toUpperCase;
    app.config.globalProperties.dateToHuman = dateToHuman;
    app.config.globalProperties.dateToHumanShort = dateToHumanShort;
    app.config.globalProperties.capitalyze = capitalyze;

    // component
    app.component('nego-footer', NegoFooter);
    app.component('nego-header', NegoHeader);
    app.component('nego-menu', NegoMenu);
    app.component('nego-card', NegoCard);
    app.component('nego-card-data', NegoCardData);
    app.component('nego-card-stats', NegoCardStats);
    app.component('nego-slider', NegoSlider);
    app.component('nego-slider-data', NegoSliderData);
    app.component('nego-list-checkbox', NegoListCheckBox);
    app.component('nego-calendar', NegoCalendar);
    app.component('nego-input-range-calendar', NegoFormInputRangeDatePicker);
    app.component('nego-radio', NegoRadio);
    app.component('nego-dropdown', NegoDropdown);
    app.component('nego-form-title', NegoFormTitle);
    app.component('nego-form-input', NegoFormInput);
    app.component('nego-form-dropdown', NegoFormDropDown);
    app.component('nego-form-text-area', NegoTextArea);
    app.component('nego-form-date-picker', NegoFormDatePicker);
    app.component('nego-form-explain', NegoFromExplain);
    app.component('nego-form-action-confirm', NegoFormActionConfirm);
    app.component('nego-form-checkbox', NegoFormCheckBox);

    // Add externals dependencies
    installExternals(app);
}

export default {
    install
};
