
import { defineComponent } from 'vue';
import CampainTemplate from '@/components/Campaign/Campaign-template.vue';

export default defineComponent({
    name: 'CampaignDelete',
    components: { CampainTemplate },
    methods: {},
    data() {
        return {};
    }
});
