
import { defineComponent } from 'vue';
import AnalyseComponent from '@/components/Analyse.vue';

export default defineComponent({
    name: 'Analyse',
    components: { AnalyseComponent },
    data() {
        return {
            data: {}
        };
    }
});
