import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row py-3 px-2 justify-content-center  py-3 px-2" }
const _hoisted_2 = { class: "col-10 fs-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanningComponent = _resolveComponent("PlanningComponent")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, _toDisplayString(_ctx.dateToHuman(new Date())), 1)
    ]),
    (_ctx.data[0])
      ? (_openBlock(), _createBlock(_component_PlanningComponent, {
          key: 0,
          data: _ctx.data,
          class: "h-100 w-100"
        }, null, 8, ["data"]))
      : _createCommentVNode("", true)
  ], 64))
}