import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "d-flex f-row justify-content-center" }
const _hoisted_3 = { class: "btn btn-transparent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tooltip = _resolveComponent("a-tooltip")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock("label", {
          key: 0,
          for: `_nego_form_input_${_ctx.label}`,
          class: "form-label fw-bold fs-6 nego-basic-label"
        }, _toDisplayString(_ctx.toUpperCase(_ctx.label)), 9, ["for"]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_2, [
      _withDirectives(_createVNode("input", {
        type: _ctx.type || '',
        class: "form-control nego-form-checkbox",
        id: `_nego_form_input_${_ctx.label}`,
        placeholder: _ctx.placeHolder || '',
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputValue = $event)),
        disabled: _ctx.disabled
      }, null, 8, ["type", "id", "placeholder", "disabled"]), [
        [_vModelDynamic, _ctx.inputValue]
      ]),
      (_ctx.help)
        ? (_openBlock(), _createBlock(_component_a_tooltip, {
            key: 0,
            title: _ctx.help.content,
            placement: "top",
            "arrow-point-at-center": ""
          }, {
            default: _withCtx(() => [
              _createVNode("button", _hoisted_3, [
                _createVNode("img", {
                  src: require('@/assets/svg/help.svg'),
                  width: "20"
                }, null, 8, ["src"])
              ])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true)
    ])
  ]))
}