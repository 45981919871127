
import { defineComponent } from 'vue';
import { INegoMenu } from './Menu';

export default defineComponent({
    name: 'Menu',
    props: {
        menu: {
            type: Object as () => INegoMenu,
            required: false
        }
    },
    methods: {
        redirect(link: string) {
            this.$router.push(link);
        }
    }
});
