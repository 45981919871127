
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FormExplain',
    props: {
        label: { type: String, required: false },
        text: { tyme: String, required: false }
    }
});
