import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row h-100 pb-5" }
const _hoisted_2 = { class: "col-2 h-100 d-flex flex-row" }
const _hoisted_3 = { class: "col-8 d-flex flex-column px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", {
        class: ["fs-5 w-100 mx-3 text-end", _ctx.titleClass ? _ctx.titleClass : 'text_orange fw-bold']
      }, _toDisplayString(_ctx.upperCase(_ctx.title)), 3),
      _createVNode(_component_a_divider, {
        type: "vertical",
        style: {"height":"100%","background-color":"#707070"}
      })
    ]),
    _createVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}