
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AnalyseComponent',
    props: {
        data: { type: [], required: false }
    },
    components: {}
});
