
import { defineComponent } from 'vue';
import CampainTemplate from '@/components/Campaign/Campaign-template.vue';

export default defineComponent({
    name: 'CampaignCreation',
    components: { CampainTemplate },
    methods: {},
    data() {
        return {
            id: '',
            alias: ''
        };
    }
});
