
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Form-title',
    components: {},
    props: {
        title: { type: String, required: true },
        titleClass: { type: String, required: false }
    },
    methods: {
        upperCase: (value: string) => {
            return value.toUpperCase();
        }
    }
});
