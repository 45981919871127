
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'SliderData',
    emits: ['update:value'],
    props: {
        value: { type: Number, required: true },
        label: { type: String, required: false },
        min: { type: Number, required: true },
        max: { type: Number, required: true },
        disabled: { type: Boolean, required: false },
        isPercentOfRange: { type: Boolean, required: false }
    },
    data() {
        return {
            sliderValue: this.value,
            humanValue: this.isPercentOfRange
                ? Math.round(
                      ((this.value - this.min) * 100) / (this.max - this.min)
                  ) + ' %'
                : Math.round((this.value / this.max) * 100) + ' %'
        };
    },
    watch: { value: 'emitValue' },
    methods: {
        emitValue() {
            this.$emit('update:value', this.value);
        }
    }
});
