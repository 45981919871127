import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "nego-drodown" }
const _hoisted_2 = { selected: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("select", {
      class: "form-select",
      disabled: !_ctx.disabled,
      "aria-label": "Default select example"
    }, [
      _createVNode("option", _hoisted_2, _toDisplayString(_ctx.value), 1)
    ], 8, ["disabled"])
  ]))
}