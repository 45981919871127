
import { defineComponent } from 'vue';
import { IHelp } from './../index.d';

export default defineComponent({
    name: 'FormInput',
    emits: ['update:value'],
    watch: { date: 'updateValue' },
    methods: {
        updateValue() {
            this.$emit('update:value', this.inputValue);
        }
    },
    data() {
        return {
            inputValue: this.value || ''
        };
    },
    props: {
        label: { type: String, required: false },
        value: { type: String, required: true },
        placeHolder: { type: String, required: false },
        type: { type: String, required: false },
        disabled: { tyme: Boolean, required: false },
        help: { type: Object as () => IHelp, required: false }
    }
});
