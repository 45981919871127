import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row w-100" }
const _hoisted_2 = { class: "form-check-label px-3" }
const _hoisted_3 = { class: "form-check form-switch" }
const _hoisted_4 = {
  class: "form-check-label",
  for: "flexSwitchCheckChecked"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.title)), 1),
    _createVNode("div", _hoisted_3, [
      _createVNode("label", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.label)), 1),
      _createVNode("input", {
        class: "form-check-input",
        type: "checkbox",
        id: "flexSwitchCheckChecked",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isChecked = !_ctx.isChecked)),
        checked: _ctx.isChecked
      }, null, 8, ["checked"])
    ])
  ]))
}