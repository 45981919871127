import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "flex items-centx²er" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_date_picker = _resolveComponent("v-date-picker")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock("label", {
          key: 0,
          for: `_nego_form_input_${_ctx.label}`,
          class: "form-label fw-bold fs-6 nego-basic-label"
        }, _toDisplayString(_ctx.toUpperCase(_ctx.label)), 9, ["for"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_date_picker, {
      locale: "fr-FR",
      "update-on-input": false,
      "model-config": _ctx.modelConfig,
      class: "inline-block h-full",
      modelValue: _ctx.date,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.date = $event)),
      mode: _ctx.mode || '',
      mask: 
                _ctx.mask || {
                    input: 'YYYY-MM-DD h:mm'
                }
            
    }, {
      default: _withCtx(({ inputValue, inputEvents, togglePopover }) => [
        _createVNode("div", _hoisted_2, [
          _createVNode("input", _mergeProps(_toHandlers(inputEvents), {
            value: inputValue,
            class: "bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
          }), null, 16, ["value"]),
          (!_ctx.noCalendar)
            ? (_openBlock(), _createBlock("button", {
                key: 0,
                class: "p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none",
                onClick: ($event: any) => (togglePopover())
              }, [
                _createVNode("img", {
                  src: require('@/assets/svg/button_calendar.svg'),
                  width: "20"
                }, null, 8, ["src"])
              ], 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.durationMode)
            ? (_openBlock(), _createBlock("button", {
                key: 1,
                class: "p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none",
                onClick: ($event: any) => (togglePopover())
              }, [
                _createVNode("img", {
                  src: require('@/assets/svg/button_duration.svg'),
                  width: "20"
                }, null, 8, ["src"])
              ], 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["model-config", "modelValue", "mode", "mask"])
  ]))
}