import * as Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from '@/i18n';

import NegoInterfaces from '@/lib/nego-interfaces/main';

const app = Vue.createApp(App);
app.use(store);
app.use(router);
app.use(i18n);

app.use(NegoInterfaces);

app.mount('#app');
