import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "nego_list_checkbox" }
const _hoisted_2 = { class: "me-0 ms-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
      return (_openBlock(), _createBlock("div", {
        class: "form-check fs-5 mx-3",
        key: '_nego_list_checkbox_' + item.label
      }, [
        _createVNode("input", {
          class: "form-check-input",
          type: "checkbox",
          value: "",
          onClick: ($event: any) => (item.value = !item.value),
          id: 'nego_list_checkbox_' + item.label,
          disabled: !_ctx.disabled
        }, null, 8, ["onClick", "id", "disabled"]),
        _createVNode("label", {
          class: "form-check-label d-flex flex-row w-100",
          for: 'nego_list_checkbox_' + item.label
        }, [
          _createVNode("div", null, _toDisplayString(item.label), 1),
          _createVNode("div", _hoisted_2, _toDisplayString(item.quantity), 1)
        ], 8, ["for"])
      ]))
    }), 128))
  ]))
}