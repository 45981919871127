
import { defineComponent, ref } from 'vue';
import PlanningComponent from '@/components/Planning.vue';
import { fakeData } from '@/services';

export default defineComponent({
    name: 'Planning',
    components: { PlanningComponent },
    setup() {
        const data = ref(null);
        fakeData().subscribe(value => {
            data.value = value;
        });

        return { data };
    }
});
